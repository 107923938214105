/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Authorize, getPkceCodeVerifier } from '@hmh/eds-login-js';
import { LoadingButton } from '@hmhco/loading-button';
import Typography from '@mui/material/Typography';
import { usePlatform } from '@hmhco/platform-helper';
import Link from '@mui/material/Link';
import { PLATFORMS, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import Button from '@mui/material/Button';
import useStyles from './ActionButtons.styles';
import {
  getCountryStateOrg,
  getCurrentOrg,
  getCurrentPid,
  getRenderNextButton,
} from '../../../store/selectors/CountryStateOrg';
import {
  setCachedOrgInfo,
  setNextButtonPressedFlag,
} from '../../../storageHelpers/sessionStorageAccessors';
import { setConnectionPid } from '../../../storageHelpers/localStorageAccessors';
import {
  getPassword,
  getShowUsernameAndPassword,
  getUsername,
} from '../../../store/selectors/UsernameAndPassword';
import {
  getClientId,
  LOGIN_FLOWS,
  NON_SSO_ISSUER,
} from '../../../constants/constants';
import {
  getLoginFlow,
  getLoginStep,
} from '../../../store/selectors/TenantPickerState';
import { getConfig } from '../../../api/buildAuthenticationUrl';
import loginHnmClient from '../../../api/loginHnm';
import loginClient from '../../../api/login';
import { getSignedInUrl } from '../../../api/buildAuthenticationUrlHnm';

const { loginHnm } = loginHnmClient();
const { login } = loginClient();

/**
 * @summary Checks feature flagging for okta embedded widget, or SSO redirection. Otherwise signs in via eds login.
 * @returns Button
 */
const ActionButtons = () => {
  const { cx, classes } = useStyles();
  const orgPid = useSelector(getCurrentPid);
  const orgId = useSelector(getCurrentOrg);
  const renderSignInButton = useSelector(getShowUsernameAndPassword);
  const renderNextButton = useSelector(getRenderNextButton);
  const orgData = useSelector(getCountryStateOrg);
  const username = useSelector(getUsername);
  const password = useSelector(getPassword);
  const loginFlow = useSelector(getLoginFlow);
  const loginStep = useSelector(getLoginStep);
  const [isGenerating, setIsGenerating] = useState(false);
  const { edsInitUrl } = getConfig();
  const platform = usePlatform();

  const { forgotPassword, requestSigninDetails } = getConfigForCurrentEnv(
    PLATFORMS.ED,
  );

  const forgotPasswordLink = forgotPassword.replace(
    '/forgot-password',
    '/tenant-picker/forgot-password',
  );

  const requestSignInDetailsLink = requestSigninDetails.replace(
    '/request-signin-details',
    '/tenant-picker/request-signin-details',
  );

  const handleIntegratedLoginFlow = () => {
    setNextButtonPressedFlag();
    setConnectionPid(orgPid);
    setCachedOrgInfo(orgPid, orgData);
    if (platform === PLATFORMS.FLIGHT) {
      return loginHnm({ pid: orgPid, username, password });
    }
    return login({ pid: orgPid, username, password });
  };

  const handleContinueToSignIn = async () => {
    setConnectionPid(orgPid);
    setCachedOrgInfo(orgPid, orgData);

    const isSecure = !!window.location.protocol.match('https:?');
    const pkceCodeChallengeMethod = isSecure ? 'S256' : 'plain';

    await getPkceCodeVerifier(pkceCodeChallengeMethod).then(
      ({ challenge, verifier }) => {
        sessionStorage.setItem('verifier', verifier);
        sessionStorage.setItem('challenge', challenge);
      },
    );

    const authorize = new Authorize(getClientId(), NON_SSO_ISSUER, edsInitUrl, {
      connection: orgPid,
      targetLinkUri: getSignedInUrl(),
      login_hint: orgId.orgRefId,
    });

    authorize
      .getThirdPartyLoginUri({ loginHint: orgId.orgRefId })
      .then(loginInitUri => {
        window.location = loginInitUri;
      })
      .catch(() => {
        setIsGenerating(false);
      });
  };

  const clickHandler = async event => {
    setIsGenerating(true);
    event.preventDefault();
    if (loginStep === 1) {
      await handleContinueToSignIn();
    }
    if (loginStep === 2) {
      if (loginFlow === LOGIN_FLOWS.INTEGRATED) {
        handleIntegratedLoginFlow();
      }
    }
  };

  const getButtonText = () => {
    if (loginStep === 1) {
      return 'tenantPicker.continueToSignIn';
    }
    return 'tenantPicker.signIn';
  };

  if (loginFlow === LOGIN_FLOWS.OKTA && loginStep === 2) {
    return null;
  }

  return (
    <div className={classes.root}>
      {loginStep === 2 && (
        <div className={classes.actionLinkContainer}>
          <Typography variant="body1">
            <Link href={forgotPasswordLink} target="_self">
              <FormattedMessage id="login.form.forgotPassword.link" />
            </Link>
          </Typography>
        </div>
      )}
      <div
        className={cx(classes.actionLinkContainer, {
          [classes.actionLinkContainerMultiple]: loginStep === 2,
        })}
      >
        {loginStep === 2 && (
          <Button
            size="small"
            target="_self"
            className={cx(
              classes.signInDetailsLink,
              classes.signInDetailsButton,
            )}
            component={Link}
            href={requestSignInDetailsLink}
            role="link"
            variant="outlined"
          >
            <FormattedMessage id="tenantPicker.requestSiginDetails" />
          </Button>
        )}
        <FormattedMessage id={getButtonText()}>
          {([label]) => (
            <LoadingButton
              loading={isGenerating}
              disabled={(!renderSignInButton && !renderNextButton) || !orgId}
              buttonProps={{
                children: label,
                color: 'primary',
                className: classes.button,
                variant: 'contained',
                size: 'small',
                onClick: clickHandler,
                type: 'submit',
              }}
              loadingText={label}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default ActionButtons;
