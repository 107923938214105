import React from 'react';
import useSSORedirect from '../../hooks/useSSORedirect.hook';
import TenantPickerLayout from './Layout/TenantPickerLayout';
import useBootstrapTenantPicker from '../../hooks/useBootstrapTenantPicker.hook';
import useCheckLoginCookies from '../../hooks/checkLoginCookies.hook';
import Alerts from './Alerts/Alerts';
import Heading from './Heading/Heading';
import HCPCountryStateAndOrgForm from '../CountryStateAndOrg/HCPCountryStateAndOrgForm';
import HCPUsernameAndPasswordForm from '../UsernameAndPassword/HCPUsernameAndPasswordForm';
import ActionButtons from './ActionButtons/ActionButtons';
import OktaForm from '../OktaSignInWidget/OktaForm';
import Logo from './Logo/Logo';

const TenantPickerPage = () => {
  useSSORedirect();
  useBootstrapTenantPicker();
  useCheckLoginCookies();

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        <Alerts />
        <Heading />
      </header>
      <main>
        <form>
          <HCPCountryStateAndOrgForm />
          <HCPUsernameAndPasswordForm />
          <OktaForm />
          <ActionButtons />
        </form>
      </main>
    </TenantPickerLayout>
  );
};

export default TenantPickerPage;
